exports.components = {
  "component---src-components-news-archive-index-js": () => import("./../../../src/components/news_archive/index.js" /* webpackChunkName: "component---src-components-news-archive-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-podcasts-js": () => import("./../../../src/pages/podcasts.js" /* webpackChunkName: "component---src-pages-podcasts-js" */),
  "component---src-pages-shortlist-[id]-js": () => import("./../../../src/pages/shortlist/[id].js" /* webpackChunkName: "component---src-pages-shortlist-[id]-js" */),
  "component---src-pages-shortlist-index-js": () => import("./../../../src/pages/shortlist/index.js" /* webpackChunkName: "component---src-pages-shortlist-index-js" */),
  "component---src-pages-voices-js": () => import("./../../../src/pages/voices.js" /* webpackChunkName: "component---src-pages-voices-js" */),
  "component---src-templates-artist-js": () => import("./../../../src/templates/artist.js" /* webpackChunkName: "component---src-templates-artist-js" */),
  "component---src-templates-news-article-js": () => import("./../../../src/templates/news-article.js" /* webpackChunkName: "component---src-templates-news-article-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

