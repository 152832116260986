export const getHostName = () => {
  if (typeof window !== 'undefined') {
    return window?.location?.hostname || document?.location?.hostname;
  }
}

export function slugify(str) {
  str = str.replace(/^\s+|\s+$/g, '');
  str = str.toLowerCase();
  var stars = '';
  if (str.slice(0, 5) === '*****') {
    stars = 'five stars'
  } else if (str.slice(0, 4) === '****') {
    stars = 'four stars'
  } else if (str.slice(0, 3) === '***') {
    stars = 'three stars'
  } else if (str.slice(0, 2) === '**') {
    stars = 'two stars'
  } else if (str.slice(0, 1) === '*') {
    stars = 'one star'
  }
  str = stars + str;
  var from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;'";
  var to = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa-------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }
  str = str.replace(/[^a-z0-9 -]/g, '').replace(/\s+/g, '-').replace(/-+/g, '-');
  return str;
}

export function artistname(artist) {
  let artist_name = artist.first_name;
  if (artist.surname) {
      artist_name = artist_name + ' ' + artist.surname;
  }
  return artist_name.trim();
}

export function formatDate(date) {
  return (date);
}
