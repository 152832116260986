import React, { useEffect } from "react";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import GlobalStyle from "../components/globalstyle";
import Nav from '../components/nav'
import Footer from "../components/footer";


const Layout = ({ children }) => {

  useEffect(() => {
    CookieConsent.run({
      guiOptions: {
          consentModal: {
              layout: "box",
              position: "bottom left",
              equalWeightButtons: true,
              flipButtons: false
          },
          preferencesModal: {
              layout: "box",
              position: "right",
              equalWeightButtons: true,
              flipButtons: false
          }
      },
      categories: {
          necessary: {
              readOnly: true
          },
          analytics: {}
      },
      language: {
          default: "en",
          autoDetect: "browser",
          translations: {
              en: {
                  consentModal: {
                      title: "This website uses cookies",
                      description: "We use cookies to improve your experience and to analyse our traffic. We do not share information about your use of our site with any third parties other than for the use of analysing our site traffic.",
                      acceptAllBtn: "Accept all",
                      acceptNecessaryBtn: "Reject all",
                      showPreferencesBtn: "Manage preferences",
                      footer: "<a href=\"/privacy-policy.pdf\">Privacy Policy</a>\n<a href=\"/terms\">Terms and conditions</a>"
                  },
                  preferencesModal: {
                      title: "Consent Preferences Center",
                      acceptAllBtn: "Accept all",
                      acceptNecessaryBtn: "Reject all",
                      savePreferencesBtn: "Save preferences",
                      closeIconLabel: "Close modal",
                      serviceCounterLabel: "Service|Services",
                      sections: [
                          {
                              title: "Strictly Necessary Cookies <span class=\"pm__badge\">Always Enabled</span>",
                              linkedCategory: "necessary"
                          },
                          {
                              title: "Analytics Cookies",
                              linkedCategory: "analytics"
                          },
                      ]
                  }
              }
          }
      }
  });
}, []);


  return (
    <>
      <GlobalStyle />
      <Nav />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
