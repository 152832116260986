import { create }  from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

let store = (set) => (
  {

    shortlistname: '',
    setShortlistName: shortlistname => set({ shortlistname }),
    getShortlistName: () => useStore.getState().shortlistname,
    clearShortlistName: () => set({ shortlistname: '' }),

    shortlist: [],
    setShortlist: shortlist => set({ shortlist }),
    addToShortlist: artist => set(state => ({ shortlist: [...state.shortlist, artist] })),
    removeFromShortlist: id => set(state => ({ shortlist: state.shortlist.filter(artist => artist._id !== id) })),
    toggleShortlist: artist => {
      const { id } = artist;
      if (useStore.getState().shortlist.some(item => item.id === id)) {
        useStore.getState().removeFromShortlist(id);
      } else {
        useStore.getState().addToShortlist(artist);
      }
    },
    clearShortlist: () => set({ shortlist: [] }),
  })

  store = persist(store, { name: 'vobj', storage: createJSONStorage(() => sessionStorage)})

const useStore = create(store)

export default useStore
