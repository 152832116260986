import React, { useState } from 'react'
import styled from 'styled-components'
import { Tooltip } from 'react-tooltip'
import { Link } from 'gatsby'

import LogoCobj from '../../images/cobj.gif'
import LogoPBJ from '../../images/pbj.gif'
import LogoVOBJ from '../../images/vobj.gif'
import LogoKBJ from '../../images/kbj.svg'

import Twitter from '../../images/twitter.svg'
import Instagram from '../../images/instagram.svg'
import LinkedIn from '../../images/linkedin.svg'

import TwitterOrange from '../../images/twitter_pink.svg'
import InstagramOrange from '../../images/instagram_pink.svg'
import LinkedinOrange from '../../images/linkedin_orange.svg'

const Container = styled.footer`
  max-width: calc(1400px - 4rem);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  min-height: 130px;
  justify-content: space-around;
  align-items: center;
  border-top: 1px solid #EBEAEA;
  column-gap: 17px;
  @media (max-width: 880px) {
    flex-direction: column;
    padding-top: 2rem;
    padding-bottom: 1rem;
    max-width: calc(100vw - 1rem);
    min-height: 260px;
  }
  a {
    font-family: Catamaran, sans-serif;
    color: #000;
  }
  a.pbj {
    text-decoration: none;
  }
  a span {
    font-weight: bold;
    font-family: Catamaran, sans-serif;
    color: var(--pink);
  }
`

const Text = styled.div`
  font-family: Catamaran, sans-serif;
  font-size: 14px;
  color: #000;
  padding-left: 1rem;
  padding-right: 1rem;
  .vobj {
    color: var(--orange);
    text-decoration: none;
    font-weight: light;
  }
  a {
    white-space: nowrap;
  }
  @media (max-width: 1250px) {
    br {
      display: none;
    }
  }
  @media (max-width: 880px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    max-width: calc(100vw - 1rem);
  }
  @media (max-width: 400px) {
    padding-bottom: 2rem;
  }
`

const Companies = styled.div`
  height: 50px;
  display: flex;
  column-gap: 17px;
  min-width: 20%;
  flex-wrap: nowrap;
  min-width: 250px;
  @media(max-width: 880px) {
    padding-top: 1rem;
  }
  img {
    width: 49px;
  }
`

const Socials = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  min-width: 20%;
  column-gap: 18px;
  @media(max-width: 880px) {
    padding-top: 2rem;
  }
  img.instagram {
    width: 30px;
    height: 30px;
  }
  img.twitter {
    width: 26px;
    height: auto;
  }
  img.linkedin {
    width: auto;
    height: 28px;
  }
`

const Outer = styled.div`
  border-top: 1px solid #D8D5D5;
`

const Left = styled.div`
  display: flex;
  align-items: center;
  @media(max-width: 880px) {
    align-items: center;
    flex-direction: column;
  }
`

const Footer = () => {

  const year = new Date().getFullYear();

  const [tw, setTw] = useState(Twitter);
  const [ins, setIns] = useState(Instagram);
  const [lkd, setLkd] = useState(LinkedIn);

  return (
    <Outer>
    <div className="no-print">
      <Tooltip id="footer-tooltip" style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', opacity: '0.99', border: '1px solid #fff', padding: '1.5rem', maxWidth: '300px', backgroundColor: '#EF7A11', fontFamily: 'Catamaran, sans-serif' }} />
      <Container>
        <Left>
          <Text>
            <Link className="vobj" to="/"><span>VOBJ</span></Link>. Boutique Voiceover Management. | Copyright © 2015-{year} PBJ & KBJ Management
            | <a href="/privacy-policy.pdf">Privacy Policy</a> | <Link to="/cookie">Cookie Policy</Link><br /> All images and text on this site are strictly under copyright. No unauthorsed reproduction. <Link to="/terms">View terms</Link>.
          </Text>
          <Companies>
            <a href="https://kbjmanagement.co.uk/" data-tooltip-id="footer-tooltip" data-tooltip-content="Representing talent for broadcast, brand & digital, literary & live." data-tooltip-place="top"><img className="logo" src={LogoKBJ} alt="KBJ" /></a>
            <a href="http://cobj.co.uk/" data-tooltip-id="footer-tooltip" data-tooltip-content="COBJ is a sister company of PBJ Management and handles all corporate enquiries for PBJ and KBJ clients." data-tooltip-place="top"><img className="logo" src={LogoCobj} alt="COBJ" /></a>
            <a href="https://pbjmanagement.co.uk/" data-tooltip-id="footer-tooltip" data-tooltip-content="PBJ Management is an exclusive UK based talent management outfit run by Peter Bennett-Jones and Caroline Chignell and their colleagues." data-tooltip-place="top"><img className="logo" src={LogoPBJ} alt="PBJ" /></a>
            <Link to="/" data-tooltip-id="footer-tooltip" data-tooltip-content="VOBJ is the voice-over arm of PBJ Management and handles all voice-over enquiries for PBJ and KBJ clients." data-tooltip-place="top"><img src={LogoVOBJ} className="logo" alt="VOBJ" /></Link>
          </Companies>
        </Left>
        <Socials>
          <a href="https://www.instagram.com/vobjmanagement"><img onMouseLeave={(e) => setIns(Instagram)} onMouseEnter={(e) => setIns(InstagramOrange)} className="instagram" src={ins} alt="Instagram" /></a>
          <a href="https://x.com/vobjmanagement"><img onMouseLeave={(e) => setTw(Twitter)} onMouseEnter={(e) => setTw(TwitterOrange)} className="twitter" src={tw} alt="Twitter" /></a>
          <a href="https://www.linkedin.com/company/vobj-management/"><img onMouseLeave={(e) => setLkd(LinkedIn)} onMouseEnter={(e) => setLkd(LinkedinOrange)} className="linkedin" src={lkd} alt="LinkedIn" /></a>
        </Socials>
      </Container>
    </div>
    </Outer>
  )
}

export default Footer
